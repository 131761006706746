<template>

  <add-accounting-setup  v-if="showAdd" v-model="showAccountingModal" @close="closeDialog" @save="save"></add-accounting-setup>
  <div class="pa-0" v-else>
    <v-row class="pa-0 ma-0">
      <v-col class="hb-table-label pl-4 mx-0" cols="2">
        <div>
          <span class="font-weight-medium">Accounting Type</span>
        </div>
      </v-col>
      <v-col class="pa-0 ma-0" cols="10">
          <!-- code here -->
          <div class="px-4 pt-4 hb-text-light" v-if="activelyEditing == 'accounting_type'"  >Select the type of accounting your company uses</div>
          <hoverable-edit-field
          :activeEditing="activelyEditing == 'accounting_type'"
          :hasPermissionToEdit="hasPermission('select_accounting_book')"
          name="accounting_type"
        >
          <!-- Editable mode -->
          <template slot="editingMode">
              <div class="pt-0">
                  <v-row class="pa-0 ma-0">
                    <v-col cols="5" class="ma-0 pa-0">
                      <div>
                        <v-select
                          :items="available_types"
                          label="Accounting Type"
                          hide-details
                          attach
                          item-text="name" 
                          item-value="id"
                          class="pa-0 mt-0"
                          single-line
                          v-model="accounting_type"
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
              </div>
          </template>
          <!-- Read mode -->
          <template slot="readMode" v-if="!activelyEditing">
            <div class="pt-1">
            {{ accountingType.name }}
            </div>
          </template>
        </hoverable-edit-field>
          <!-- code end here -->
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <hb-modal size="medium" show-help-link v-model="confirm_change" title="Confirmation" confirmation>
        <template v-slot:content>
          <div class="px-6 py-4">You are changing your accounting type. This will affect your journal Templates for recording events. Please visit that section to create appropriate setup.</div>
          <div class="px-6 pb-4">Are you sure?</div>
        </template>
        <template v-slot:actions>
          <hb-btn color="primary" @click="save(accounting_type,true)"
            >Save</hb-btn
          >
        </template>
      </hb-modal>
  </div>

</template>
<script>
import api from '../../../assets/api.js';
import HoverableEditField from "../../assets/HoverableEditField.vue";
import AddAccountingSetup from "./AddAccountingSetup.vue";
import { EventBus } from '../../../EventBus.js';
import { mapGetters } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
  name: 'AccountingSetup',
  data(){
    return {
      accountingType : {},
      accounting_type: null,
      last_accounting_type: null,
      available_types:[{id: '0', name: 'Cash Book'},{id: '1', name: 'Accrual Book'},{id: '2', name: 'Double Book'}],
      showAccountingModal : false,
      activelyEditing: '',
      confirm_change: false,
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    showAdd(){
      return this.accountingType && this.accountingType.active ? false : true
    }
  },
  mixins:[notificationMixin],
  components: {
    HoverableEditField,
    AddAccountingSetup
  },
  async created() {
    EventBus.$on('activeEdit', this.setActiveEditName);
    EventBus.$on('saveData', this.updateAccountingType);
    await this.fetchAccountingType();
  },
  destroyed (){
    EventBus.$off('saveData', this.updateAccountingType);
    EventBus.$off('activeEdit', this.setActiveEditName);
    this.closeMessageNotification();
  },
  methods: {
    async fetchAccountingType(){
      let p = await api.get(this, api.ACCOUNTING + 'accounting-type');
      if(p.accounting_type && p.accounting_type.length > 0){
          this.accounting_type = p.accounting_type[0].book_id;
          this.last_accounting_type = p.accounting_type[0].book_id;
          this.accountingType = p.accounting_type[0];
          if(p.accounting_type[0].book_id === '0' ) this.accountingType.name = "Cash Book";
          if(p.accounting_type[0].book_id === '1' ) this.accountingType.name = "Accrual Book";
          if(p.accounting_type[0].book_id === '2' ) this.accountingType.name = "Double Book";
          
      } else {
        this.showAccountingModal = true;
      }
    },

    updateAccountingType(){
      if(this.last_accounting_type == this.accounting_type) return;
      this.confirm_change = true;
    },

    closeDialog(closedEmpty){
      if(closedEmpty){
        this.$emit('closeExpansionPanel')
      }
      this.showAccountingModal = false;
    },
    refresh(){
      setTimeout(() => {
        this.$emit('refresh');
      }, 250);
    },

    setActiveEditName(name) {
      this.accounting_type = this.last_accounting_type;
      this.activelyEditing = name;
    },

    async save(new_book_id,isEdit){
      let description = '';

      if(new_book_id === '0' ) description = "cash book"
      if(new_book_id === '1' ) description = "accrual book"
      if(new_book_id === '2' ) description = "double book"

      let data = {book: new_book_id, description: description}

      try {
        if(isEdit) {
          await api.put(this, api.ACCOUNTING + 'accounting-type',data);
          this.confirm_change = false;
          this.refresh();
          this.showMessageNotification({ id: this.$options.name, type: "success", description: 'You’ve changed your accounting type to ' + description.split(" ")[0] + '.' });
        } else {
          await api.post(this, api.ACCOUNTING + 'accounting-type',data);
          this.showMessageNotification({ id: this.$options.name, type: "success", description: 'You’ve set your accounting type to ' + description + '. Now set up your chart of accounts and journal templates.' });
          this.closeDialog();
          this.refresh();
        }
      } catch(err) {
        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }], });
      }
    }
  }
}
</script>
<style scoped>

</style>