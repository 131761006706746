<template>
    <hb-modal v-model="dialog" show-help-link size="large" :title="'Accounting Setup'" :no-sub-header="false" @close="closeModal">
        <template v-slot:subheader>
            <span class="hb-text-night"> Enter your company's accounting type and closing time to get started.</span>
        </template>
        <template v-slot:content>
            <v-row class="hb-table-row ma-0 pa-0">
                <v-col class="hb-forms-label" cols="4">Accounting Type</v-col>
                <v-col cols="8" class="ma-0 pa-0">
                    <div class="ma-0 px-5 pt-3 hb-text-lighter">Select the type of accounting your company uses</div>
                    <v-row class="mx-0 pb-0 mb-3 px-5 hb-forms-content-row">
                        <v-col cols="12" class="pt-5 px-0 ma-0" style="min-height: 30em">
                            <v-select
                                :disabled="!hasPermission('select_accounting_book')"
                                :items="available_types"
                                label="Select Type"
                                hide-details
                                attach
                                item-text="name" 
                                item-value="id"
                                class="pa-0 mt-0"
                                single-line
                                v-model="new_accounting_id"
                        ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isDisabledButton" @click="save">
                <template >Save</template>
            </hb-btn>
        </template>
    </hb-modal>
</template>
<script>

import { mapGetters } from "vuex";
export default {
    name: 'AddAccountingSetup',
    data(){
        return{
            new_accounting_id : null,
            available_types:[{id: '0', name: 'Cash Book'},{id: '1', name: 'Accrual Book'},{id: '2', name: 'Double Book'}],
            isDisabledButton: true
        }
    },
    props:['value'],
    computed: {
        ...mapGetters({
            hasPermission: 'authenticationStore/rolePermission'
        }),
       dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        emitClose(){
            setTimeout(() => {
                this.$emit('close');
            }, 250);
        },
        async save(){
            this.$emit("save",this.new_accounting_id);
        },
        closeModal(){
            this.$emit('close',true)
        }
    },
    watch: {
        new_accounting_id: function(val){
            if (this.new_accounting_id != null) this.isDisabledButton = false;
        }
    }

}
</script>
<style scoped>

</style>