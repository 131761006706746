<template>
  <div>
    <v-row class="mt-1 mb-0">
      <v-col class="pt-4 pl-4">
        <span class="hb-text-light">How you format your journal template defines how you balance your books.</span>
      </v-col>
    </v-row>
        
    <div v-for="(accounting_event, index) in accounting_events" :key="`accounting_event_book_${accounting_event.book_id}`" class="pb-5">
    <v-row class="mt-0 mb-1" :class="{'mb-4' : index !== 0}">
      <v-col cols="7" class="pt-0 pb-0 pl-4 d-flex align-center" v-if="accounting_event.accountingTypeName">
        <span class="hb-text-light capitalize pr-1" > {{accounting_event.accountingTypeName}} Journal</span>
        <hb-tooltip>
          <template slot="header">
            <span class="capitalize">{{accounting_event.accountingTypeName}} Journal</span>
          </template>
          <template slot="body">
            <span> An {{accounting_event.accountingTypeName}} journal follows the </span>
            <br>
            <span>rules of {{accounting_event.accountingTypeName.split(' ')[0] }} accounting. </span>
             
          </template>
        </hb-tooltip>
      </v-col>
      <v-col v-if="index !== 1" :cols="!accounting_event.accountingTypeName ? 12: 5" class="pt-1 pb-1 pr-3 text-right">
        <hb-btn small color="secondary" @click="downloadTemplate" :loading="isLoading($options.name)">Download Journal Templates</hb-btn>
      </v-col>
    </v-row>
    <div class="mt-2 mb-2">
      <journals
        :events="accounting_event.events"
        @addEvent="addEvent"
        @editEvent="editEvent"
        @clearEvent="clearEvent"
      ></journals>
    </div>
    </div>

    <edit-journal-event
      v-if="showEditModel"
      v-model="showEditModel"
      :selected="selected"
      :selectedTemplate="selectedTemplate"
      :isEdit="isEdit"
      @close="closeEditDialog"
      @refetch="refetchJournalEvents"
    >
    </edit-journal-event>
    
    <hb-modal
      v-model="showClearModal"
      v-if="showClearModal"
      size="medium"
      title="Clear Event Information"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="pt-6 pb-4 px-6">
          You are about to clear the assigned information for this event.
        </div>
        <div class="pb-6 px-6">
          Are you sure you want to clear the information?
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" :loading="isLoading($options.name)" @click="confirmClearEvent" >Clear</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>

  import api from '../../../assets/api.js'
  import Journals from './Journals.vue';
  import EditJournalEvent from './EditJournalEvent.vue';
  import { mapGetters, mapActions } from 'vuex';
  import { notificationMixin } from "../../../mixins/notificationMixin.js";

  export default {
    name: 'JournalTemplate',
    data() {
      return {
        events: [],
        accountingType: {},
        selected: {},
        showClearModal: false,
        showEditModel: false,
        isEdit: false,
        accounting_events: []
      }
    },
    mixins:[notificationMixin],
    components: {
      Journals,
      EditJournalEvent
    },
    async created () {
      if(this.selectedTemplate?.id && !this.selectedTemplate?.GlEvents) {
        await this.getJournalEvents({
          id: this.selectedTemplate.id
        });
        this.events = this.journalEvents;
        await this.fetchJournalEvents();
      }
      
    },
    computed: {
      ...mapGetters({
        selectedTemplate: "accountingStore/selectedTemplate",
        journalEvents: "accountingStore/journalEvents",
      }),
    },
    methods: {
      ...mapActions({
        getJournalEvents: "accountingStore/getJournalEvents"
      }),
      async refetchJournalEvents() {
        await this.getJournalEvents({
          id: this.selectedTemplate.id
        });
        this.events = this.journalEvents;
        await this.fetchJournalEvents();
      },
      async fetchJournalEvents(){
        this.accountingType = this.selectedTemplate.AccountingSetup;
        if(this.accountingType.book_id === '0' ) {
          this.accounting_events = [{
            book_id: 0,
            events: this.events && this.events.filter(e=> e.book_id == '0'),
            accountingTypeName: 'cash book'
          }]
        } else if(this.accountingType.book_id === '1' ) {
          this.accounting_events = [{
            book_id: 1,
            events: this.events && this.events.filter(e=> e.book_id == '1'),
            accountingTypeName: 'accrual book'
          }]
        } else if(this.accountingType.book_id === '2' ) {
          let cashEvents = this.events && this.events.filter(e=> e.book_id == '0');
          let accrualEvents = this.events && this.events.filter(e=> e.book_id == '1'); 
          
          this.accounting_events = [];
          if(cashEvents.length > 0) {
            this.accounting_events.push({
              book_id: 0,
              events: cashEvents,
              accountingTypeName: 'cash book'
            });
          }

          if(accrualEvents.length > 0) {
            this.accounting_events.push({
              book_id: 1,
              events: accrualEvents,
              accountingTypeName: 'accrual book'
            });
          }
        }
      },

      clearEvent(item) {
        this.selected = item;
        this.showClearModal = true;
      },

      confirmClearEvent(){
        if(!this.selected.id) return;

        api.put(this, api.ACCOUNTING + 'template/' + this.selectedTemplate.id + '/events/' + this.selected.id + '/reset').then(async (r) => {
          await this.getJournalEvents({
            id: this.selectedTemplate.id
          });
          this.events = this.journalEvents;
          await this.fetchJournalEvents();
          this.showClearModal = false;
          this.showMessageNotification({ id: this.$options.name, type: "success", description: 'You’ve cleared the event’s information.' });
        }).catch(err =>{
          this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }], });
        });
      },

      addEvent(item) {
        this.selected = item;
        this.isEdit = false;
        this.showEditModel = true;
      },

      editEvent(item) {
        this.selected = item;
        this.isEdit = true;
        this.showEditModel = true;
      },

      closeEditDialog(){
        this.showEditModel = false;
        this.isEdit = false;
        this.selected = null;
      },

      async downloadTemplate(){
        let response = await api.get(this, api.ACCOUNTING + 'template/' + this.selectedTemplate.id + '/download-je-template');
        const arr = new Uint8Array(response.buffer.data);
        var blob = new Blob([arr], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `JournalTemplate.xlsx`;
        link.click();
      }

    },
    watch: {
      async "selectedTemplate"(val) {
        if(val?.GlEvents?.length) {
          this.events = val.GlEvents;
          await this.fetchJournalEvents();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .capitalize {
    text-transform: capitalize
  }
</style>