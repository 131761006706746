<template>
  <div class="section-content ma-0 pa-0">
    <v-divider></v-divider>

    <!-- <div class="content-view"> -->

    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

    <div class="mr-6 mt-1">
      <hb-data-table-header
        left-columns="6"
        right-columns="6"
      >
        <template v-slot:description>
          Download and read the guides before you setup or edit your accounting settings.
        </template>
        <template v-slot:actions>
          <hb-btn small color="secondary" @click="downloadGuide(GUIDES.CHART_OF_ACCOUNT)">Chart of Accounts Guide</hb-btn>
          <hb-btn small color="secondary" class="ml-3" @click="downloadGuide(GUIDES.JOURNAL_TEMPLATE)">Journal Template Guide</hb-btn>
        </template>
      </hb-data-table-header>

      <v-expansion-panels elevation="0" class="hb-expansion-panel" v-model="setupModal">

        <hb-expansion-panel key="setup_panel" :top-margin="false">
          <template v-slot:title>
            Setup
          </template>
          <template v-slot:content>
            <accounting-setup :key="key_setup" @closeExpansionPanel="closeExpansionPanel" @refresh="refreshSetup" v-if="setupModal == 0"></accounting-setup>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel key="chart_of_account">
          <template v-slot:title>
            Chart of Accounts
          </template>
          <template v-slot:content>
            <chart-of-account class="mx-6 mb-6" :key="key_setup" v-if="setupModal == 1"></chart-of-account>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel key="journal_template">
          <template v-slot:title>
            Journal Templates
          </template>
          <template v-slot:content>
            <journal-template class="mx-6" :key="key_setup" v-if="setupModal == 2"></journal-template>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel key="additional_settings">
          <template v-slot:title>Additional Settings</template>
          <template v-slot:content>
            <additional-settings :key="key_setup" v-if="setupModal == 3"></additional-settings>
          </template>
        </hb-expansion-panel>


        

        <!-- <v-expansion-panel key="Other" class="my-2">
          <v-expansion-panel-header class="hb-default-font-size pt-3 pb-4 px-6">
            <v-col cols="12" class="pa-0 ma-0">
              <span class="text-h6">Other</span>
            </v-col>
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content class="px-6 pt-3 pb-4">
            <div class="horizontal-form-row">
              <div class="horizontal-form-label settings">
                <label>Name</label>
              </div>
              <div class="horizontal-form-label">
                <label>Account Number</label>
              </div>
              <div class="horizontal-form-label">
                <label>Account</label>
              </div>
            </div>
            <div class="horizontal-form-row" v-for="(method, name) in special" :key="name">
              <div class="horizontal-form-label settings">
                <label>{{ name | capitalize }} </label>
              </div>
              <div class="form-input-container" >
                <div class="horizontal-form-input" :class="{'has-error': errors.has(name + '_number') }">
                  <input
                    :id="name + '_number'"
                    :name="name + '_number'"
                    v-validate="'required'"
                    class="w-input"
                    v-model="method.account_number"
                    data-vv-as="account number"
                  />

                  <span v-show="errors.has(method.name + '_number')" class="status-block error-block text-right">{{ errors.first(name + '_number') }}</span>
                </div>
                <div class="horizontal-form-input" :class="{'has-error': errors.has(name + '_account') }">
                  <dropdown
                    forceChoice
                    :id="name + '_account'"
                    :name="name + '_account'"
                    idField="id"
                    labelField="name"
                    :options="accounts"
                    v-model="method.account_id"
                    v-validate="'required'"
                    data-vv-as="account"
                  ></dropdown>
                  <span v-show="errors.has(name + '_account')" class="status-block error-block text-right">{{ errors.first(name + '_account') }}</span>
                </div>
                <div class="horizontal-form-input">
                  <hb-btn color="primary" @click="saveProductAccount(name, method)" style="position:relative;top:-2px;">Save</hb-btn>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel> -->

        <v-expansion-panel v-if="products.length" key="product-list" class="my-2">
          <v-expansion-panel-header><div class="panel-content-header">Product List</div></v-expansion-panel-header>
          <v-expansion-panel-content class="px-6 pb-4">
            <div class="form-section">
              <div class="horizontal-form-row">
                <div class="horizontal-form-label settings">
                  <label>Product Name:</label>
                </div>
                <div class="horizontal-form-label">
                  <label>Account Number</label>
                </div>
                <div class="horizontal-form-label">
                  <label>Account</label>
                </div>
              </div>
              <div class="horizontal-form-row" v-for="(product, i) in products" :key="i">
                <div class="horizontal-form-label settings">
                  <label>{{product.name}}</label>
                </div>
                <div class="form-input-container" >
                  <div class="horizontal-form-input" :class="{'has-error': errors.has(product.id + '_number') }">
                    <input
                      :id="product.id + '_number'"
                      :name="product.id + '_number'"
                      v-validate="'required'"
                      class="w-input"
                      v-model="product.Accounting.account_number"
                      data-vv-as="account number"
                    />

                    <span v-show="errors.has(product.id + '_number')" class="status-block error-block text-right">{{ errors.first(product.id + '_number') }}</span>
                  </div>
                  <div class="horizontal-form-input" :class="{'has-error': errors.has(product.id + '_type') }">
                    <dropdown
                      forceChoice
                      :id="product.id + '_account'"
                      idField="id"
                      labelField="name"
                      :name="product.id + '_account'"
                      :options="accounts"
                      v-model="product.Accounting.account_id"
                      v-validate="'required'"
                      data-vv-as="account"
                    ></dropdown>
                    <span v-show="errors.has(product.id + '_type')" class="status-block error-block text-right">{{ errors.first(product.id + '_type') }}</span>
                  </div>
                  <div class="horizontal-form-input">
                    <hb-btn color="primary" @click="saveProductAccount(product)" style="position:relative;top:-2px;">Save</hb-btn>
                  </div>

                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel key="insurance" v-if="insurance.length" class="my-2">
          <v-expansion-panel-header><div class="panel-content-header">Insurance</div></v-expansion-panel-header>
          <v-expansion-panel-content class="px-6 pb-4">
            <div class="form-section">
              <div class="horizontal-form-row">
                <div class="horizontal-form-label settings">
                  <label>Insurance Name:</label>
                </div>
                <div class="horizontal-form-label">
                  <label>Account Number</label>
                </div>
                <div class="horizontal-form-label">
                  <label>Account</label>
                </div>
              </div>
              <div class="horizontal-form-row" v-for="(product, i) in insurance" :key="i">
                <div class="horizontal-form-label settings">
                  <label>{{product.name}}</label>
                </div>
                <div class="form-input-container" >
                  <div class="horizontal-form-input" :class="{'has-error': errors.has(product.id + '_number') }">
                    <input
                      :id="product.id + '_number'"
                      :name="product.id + '_number'"
                      v-validate="'required'"
                      class="w-input"
                      v-model="product.Accounting.account_number"
                      data-vv-as="account number"
                    />

                    <span v-show="errors.has(product.id + '_number')" class="status-block error-block text-right">{{ errors.first(product.id + '_number') }}</span>
                  </div>
                  <div class="horizontal-form-input" :class="{'has-error': errors.has(product.id + '_type') }">
                    <dropdown
                      forceChoice
                      :id="product.id + '_account'"
                      idField="id"
                      labelField="name"
                      :name="product.id + '_account'"
                      :options="accounts"
                      v-model="product.Accounting.account_id"
                      v-validate="'required'"
                      data-vv-as="account"
                    ></dropdown>
                    <span v-show="errors.has(product.id + '_type')" class="status-block error-block text-right">{{ errors.first(product.id + '_type') }}</span>
                  </div>
                  <div class="horizontal-form-input">
                    <hb-btn color="primary" @click="saveProductAccount(product)" style="position:relative;top:-2px;">Save</hb-btn>
                  </div>

                </div>
              </div>

            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel key="utilities" v-if="utilities.length" class="my-2">
          <v-expansion-panel-header><div class="panel-content-header">Utilities List</div></v-expansion-panel-header>
          <v-expansion-panel-content class="px-6 pb-4">
            <div class="form-section">
              <div class="horizontal-form-row">
                <div class="horizontal-form-label settings">
                  <label>Name:</label>
                </div>
                <div class="horizontal-form-label">
                  <label>Account Number</label>
                </div>
                <div class="horizontal-form-label">
                  <label>Account</label>
                </div>
              </div>
              <div class="horizontal-form-row"  v-for="(product, i) in utilities" :key="i">
                <div class="horizontal-form-label settings">
                  <label>{{product.name}}</label>
                </div>
                <div class="form-input-container" >
                  <div class="horizontal-form-input" :class="{'has-error': errors.has(product.id + '_number') }">
                    <input
                      :id="product.id + '_number'"
                      :name="product.id + '_number'"
                      v-validate="'required'"
                      class="w-input"
                      v-model="product.Accounting.account_number"
                      data-vv-as="account number"
                    />

                    <span v-show="errors.has(product.id + '_number')" class="status-block error-block text-right">{{ errors.first(product.id + '_number') }}</span>
                  </div>
                  <div class="horizontal-form-input" :class="{'has-error': errors.has(product.id + '_type') }">
                    <dropdown
                      forceChoice
                      :id="product.id + '_account'"
                      idField="id"
                      labelField="name"
                      :name="product.id + '_account'"
                      :options="accounts"
                      v-model="product.Accounting.account_id"
                      v-validate="'required'"
                      data-vv-as="account"
                    ></dropdown>
                    <span v-show="errors.has(product.id + '_type')" class="status-block error-block text-right">{{ errors.first(product.id + '_type') }}</span>
                  </div>
                  <div class="horizontal-form-input">
                    <hb-btn color="primary" @click="saveProductAccount(product)" style="position:relative;top:-2px;">Save</hb-btn>
                  </div>

                </div>
              </div>

            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!--        <div class="form-section">-->

    <!--          <div class="horizontal-form-row">-->
    <!--            <div class="horizontal-form-label settings">-->
    <!--              <h2>Payment Methods</h2>-->
    <!--            </div>-->
    <!--          </div>-->


    <!--          <div class="horizontal-form-row">-->
    <!--            <div class="horizontal-form-label settings">-->
    <!--              <label>Name</label>-->
    <!--            </div>-->
    <!--            <div class="horizontal-form-label">-->
    <!--              <label>Account Number</label>-->
    <!--            </div>-->
    <!--            <div class="horizontal-form-label">-->
    <!--              <label>Account</label>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="horizontal-form-row" v-for="(method, name) in payment_methods">-->
    <!--            <div class="horizontal-form-label settings">-->
    <!--              <label>{{ name | capitalize }} </label>-->
    <!--            </div>-->
    <!--            <div class="form-input-container" >-->
    <!--              <div class="horizontal-form-input" :class="{'has-error': errors.has(name + '_number') }">-->
    <!--                <input-->
    <!--                  :id="name + '_number'"-->
    <!--                  :name="name + '_number'"-->
    <!--                  v-validate="'required'"-->
    <!--                  class="w-input"-->
    <!--                  v-model="method.account_number"-->
    <!--                  data-vv-as="account number"-->
    <!--                />-->

    <!--                <span v-show="errors.has(method.name + '_number')" class="status-block error-block text-right">{{ errors.first(name + '_number') }}</span>-->
    <!--              </div>-->
    <!--              <div class="horizontal-form-input" :class="{'has-error': errors.has(name + '_account') }">-->
    <!--                <dropdown-->
    <!--                  forceChoice-->
    <!--                  :id="name + '_account'"-->
    <!--                  :name="name + '_account'"-->
    <!--                  idField="id"-->
    <!--                  labelField="name"-->
    <!--                  :options="accounts"-->
    <!--                  v-model="method.account_id"-->
    <!--                  v-validate="'required'"-->
    <!--                  data-vv-as="account"-->
    <!--                ></dropdown>-->
    <!--                <span v-show="errors.has(name + '_account')" class="status-block error-block text-right">{{ errors.first(name + '_account') }}</span>-->
    <!--              </div>-->
    <!--              <div class="horizontal-form-input">-->
    <!--                <button @click="saveProductAccount(name, method)" class="primary-btn w-button">Save</button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--        </div>-->


    <!-- <div class="form-section">

      <div class="horizontal-form-row">
        <div class="horizontal-form-label settings">
          <h2>Other</h2>
        </div>
      </div>




    </div> -->


    <!-- </div> -->

  </div>


</template>

<script type="text/babel">

    import Dropdown from '../assets/Dropdown.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import EditAccount from './Accounting/EditAccount.vue'
    import ChartOfAccount from './Accounting/ChartOfAccount.vue'
    import AccountingSetup from './Accounting/AccountingSetup.vue'
    import JournalTemplate from './Accounting/JournalTemplate.vue'
    import AdditionalSettings from './Accounting/AdditionalSettings.vue';
    import api from '../../assets/api.js';
    import GUIDES from "@/constants/guides.js";
    import { EventBus } from '../../EventBus.js';

    export default {
        name: "AccountingSettings",
        data() {
            return {
                category: 'accounting',
                activelyEditing: '',
                key_setup: 0,
                setupModal: [],
                account_subtypes: [],
                account_types: [],
                categories: [],
                products: [],
                insurance: [],
                utilities: [],
                accounts: [],
                new_account: {
                    description: '',
                    category: '',
                    acct_type: '',
                    sub_type: '',
                    gl_string: '',
                },
                payment_methods: {
                    check: { id: '', account_id: '',  account_number: '' },
                    cash: { id: '', account_id: '',  account_number: '' },
                    visa: { id: '', account_id: '',  account_number: '' },
                    mastercard: { id: '', account_id: '',  account_number: '' },
                    amex: { id: '', account_id: '',  account_number: '' },
                    google: { id: '', account_id: '',  account_number: '' },
                    apple: { id: '', account_id: '',  account_number: '' },
                    paypal: { id: '', account_id: '',  account_number: '' },
                },
                special: {
                    refunds: { id: '', account_id: '',  account_number: '' },
                    chargebacks: { id: '', account_id: '',  account_number: '' },
                    "NSF": { id: '', account_id: '',  account_number: '' },
                    "sales tax": { id: '', account_id: '',  account_number: '' },
                },
                showAddAccountModal : false,
                selected : null,
                headers: [
                    { text: "Account Name", value: "name" },
                    { text: "Category", value: "category" },
                    { text: "Account Type", value: "type" },
                    { text: "GL String", value: "string" }
                ],
            }
        },
        components:{
            Dropdown,
            Status,
            Loader,
            EditAccount,
            ChartOfAccount,
            AccountingSetup,
            JournalTemplate,
            AdditionalSettings
        },
        async created(){
          this.GUIDES = GUIDES;
            //await this.fetchAccounting();
            // await this.fetchAccountTypes();
            // await this.fetchAccountSubTypes();
            // await this.fetchAccountCategories();
            // await this.fetchAccountingRent();
            // await this.fetchAccountingLate();
            // await this.fetchAccountingSecurity();
            // await this.fetchAccountingProducts();
            // await this.fetchAccountingInsurance();
            // await this.fetchAccountingUtilities();
        },
        computed:{
        },
        watch:{
          setupModal(){
            if(typeof this.setupModal !== 'undefined') {
              this.key_setup++;
            }
          },
        },
        methods:{
            async fetchAccountCategories(){
                let p = await api.get(this, api.SETTINGS + 'accounting/categories');
                this.categories = p.categories
            },
            async fetchAccounting(){
                let p = await api.get(this, api.SETTINGS + 'accounting');
                this.accounts = p.account.COA
            },
            async fetchAccountTypes(){
                let p = await api.get(this, api.SETTINGS + 'accounting/account-types');
                this.account_types = p.account_types
            },
            async fetchAccountSubTypes(){
                let p = await api.get(this, api.SETTINGS + 'accounting/account-subtypes');
                this.account_subtypes = p.account_subtypes
            },

            async saveNewAccount(){

                let r = await api.post(this, api.SETTINGS + 'accounting/account', this.new_account);
                this.successSet(this.$options.name, "Account Created");
                this.new_account = {
                    name: '',
                    account_number: '',
                    account_type: ''
                };
                this.fetchAccounting();

            },
            async updateAccount(account){

                let data = {
                    description: account.description,
                    gl_string: account.gl_string
                }

                let r = await api.put(this, api.SETTINGS + 'accounting/account/' + account.account_id, data);
                this.successSet(this.$options.name, "Account Updated");
                this.fetchAccounting();
            },

            async saveProductAccount(product){
                let r = await api.post(this, api.SETTINGS + 'accounting/products/' + product.id, product.Accounting);
            },
            async savePaymentMethodAccount(name, method){
                let r = await api.post(this, api.SETTINGS + 'accounting/payment-methods/' + name, method);
            },
            async downloadGuide(name){
              let res = await api.get(this, api.DOCUMENT + 'guide/' + name);
              var buffer = Buffer.from( res.data);
              var blob = new Blob([buffer], {type: res.content_type });
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = res.file_name;
              link.click();
            },
            closeDialog(){
                this.showAddAccountModal = false;
                this.selected = null;
            },
            editItem(rowData){
                this.selected = rowData;
                this.showAddAccountModal = true;
            },
            showSuccessMessage(name, isUpdate = false){
                this.successSet(this.$options.name, ( isUpdate ? 'Account Updated' : 'Account "' + name + '" Added'));
            },
            closeExpansionPanel(){
              this.setupModal = null;
            },
            refreshSetup() {
              this.key_setup++;
            }
        }
    }
</script>

<style scoped>
  .add-on{
    width: 50px;
  }
  .horizontal-form-label.settings{
    width: 500px;
  }
  .text-right{
    text-align: right;
    flex-direction:row-reverse;
  }
  .panel-content-header{
    font-size: 15px;
    font-weight: 500;
    color: #101318;
  }
  .border-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .add-role-btn{
    font-size: 15px;
    text-decoration: none;
  }
  .add-role-btn:focus{
    border: none;
  }
  .keys-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    border-radius: 4px;
  }
  .keys-table .table-head{
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.22);
    /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
  }
  .keys-table .table-head strong{
    color: #101318;
    font-size: 15px;
    font-weight: 500;
  }
  .keys-table .table-row:hover:not(:first-child){
    background: #F9FAFB;
  }
  .keys-table .table-row:first-child {
    cursor: inherit;
  }
  .keys-table .table-row .table-cell{
    border-bottom: 1px solid #dce8ef;
  }
  .light-text{
    color: #637381;
  }



</style>

<style>
  .settings-accnt-panel .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 55px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .settings-accnt-panel .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0px;
  }
  .settings-accnt-panel .v-expansion-panel{
    border-radius: 4px;
  }
  .settings-accnt-panel .v-expansion-panel::before{
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.02);
  }
  .settings-accnt-panel.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
    border-color: rgba(0, 0, 0, 0);
  }
</style>
